<template>
    <b-container fluid>
        <div class="row">
            <div class="col-md-12">
                <div class="card card-primary card-outline">
                    <div class="card-header">
                        <div class="card-title">
                            <h4><i class="fa fa-briefcase"></i>Benutzerrollen</h4>
                            <p>Liste aller Benutzerrollen</p>
                        </div>

                        <div class="card-tools">
                            <button type="button" class="btn btn-default mr-1" @click="loadRoles">
                                <i class="fas fa-sync"></i>
                            </button>
                            <button type="button" class="btn btn-primary" @click="createModal" v-if="$auth.check('roles.create')">
                                <i class="fas fa-plus-circle"></i> Neue Benutzerrolle
                            </button>
                            <!-- <a href="#" class="btn btn-primary pull-right">Create User</a> -->
                        </div>
                    </div>

                    <div class="card-body">
                        <b-row class="mb-3">
                            <b-col md="3">
                                <b-form-input v-model="filter" type="search" id="filterInput" placeholder="Type to Search"></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-table striped hover outlined :fields="fields" :items="roles" :filter="filter" :per-page="perPage" :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
                                    <template #cell(actions)="row">
                                        <b-button size="sm" @click="editModal(row.item)" class="mr-1" variant="warning" v-if="$auth.check('roles.edit')"><i class="fas fa-edit"></i></b-button>
                                        <b-button size="sm" @click="deleteRole(row.item.id)" variant="danger" v-if="$auth.check('roles.destroy')"><i class="fas fa-trash"></i></b-button>
                                    </template>
                                </b-table>
                                <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="my-table"></b-pagination>
                            </b-col>
                        </b-row>
                    </div>
                </div>
            </div>
        </div>

        <b-modal id="roleModal" v-bind:title="modalTitle" ok-only ok-variant="danger" ok-title="Speichern" size="xl" @ok="handleOk($event, form.id)">
            <form ref="form" @submit.stop.prevent="handleSubmit">
                <div class="row">

                    <div class="col-md-6">
                        <div class="form-group row">
                            <label class="control-label col-sm-2 col-form-label col-form-label-sm">Name</label>
                            <div class="col-sm-9">
                                <input v-model="form.name" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('name')}" tabindex="1"/>
                                <has-error :form="form" field="name"></has-error>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">

                        <div class="form-group row">
                            <label class="control-label col-sm-2 col-form-label col-form-label-sm offset-sm-1">Guard</label>
                            <div class="col-sm-9">
                                <select v-model="form.guard_name" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('guard_name')}" tabindex="2" @change="guardChange">
                                    <option value="api">Api</option>
                                    <option value="web">Web</option>
                                    <option value="customer">Customer</option>
                                </select>
                                <has-error :form="form" field="guard_name"></has-error>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="row" v-if="groups.length >= 1">
                    <div class="col-md-3" v-for="group in groups" :key="group.id">
                        <fieldset class="form-group">
                            <legend>{{group.name}}</legend>
                            <div class="form-check" v-for="permission in group.permissions" :key="permission.id">   
                                <label class="form-check-label">
                                    <input type="checkbox" class="form-check-input" :value="permission.id" v-model="form.permissions">
                                    {{ permission.name }}
                                </label>
                            </div>
                        </fieldset>
                    </div>
                </div>

                <div class="row" v-if="groups.length == 0">
                    <div class="col-md-12">
                        <h6>Es wurden keine Berechtigungen für diesen Guard gefunden</h6>
                        <br/>
                        <h6>Bitte lege zuerst unter Permissions eine Berechtigung für diesen Guard an.</h6>
                    </div>
                </div>


            </form>
        </b-modal>


    </b-container>
</template>

<script>
export default {
    name: 'Roles',
    title: 'Benutzerrollen',
    
    data() {
        return {
            form: new window.Form({
                id: "",
                name: "",
                guard_name: "",
                permissions: [],
            }),
            roles: [],
            groups: [],
            modalTitle: '',
            editMode: false,
            edit: null,
            sortBy: "id",
            sortDesc: false,
            perPage: 20,
            currentPage: 1,
            filter: "",
            fields: [
                {key: "id", sortable: true},
                {key: "name", label: "Name", sortable: true},
                {key: "guard_name", label: "Guard", sortable: true},
                {key: "actions", label: "Actions"},
            ],
        }
    },

    methods:{
        guardChange(event){
            this.loadGroups(event.target.value);
            this.form.permissions = [];
        },

        handleOk(bvModalEvt, id) {
            bvModalEvt.preventDefault()
            this.handleSubmit(id)
        },

        handleSubmit(id) {
            if(this.editMode == true)
            {
                //Edit User
                this.editRole(id);
            }
            else
            {
                //Create User
                this.createRole();
            }
        },

        createModal() {
            this.editMode = false;
            this.groups = [];
            this.modalTitle = "Neue Benutzerrolle anlegen";
            this.form.clear();
            this.form.reset();
            this.$bvModal.show("roleModal");
        },

        editModal(Role) {
            this.loadGroups(Role.guard_name);
            this.changePassword = false;
            this.modalTitle = "Benutzerrolle editieren";
            this.editMode = true;
            this.form.reset();
            this.form.fill(Role);
            this.$bvModal.show("roleModal");
        },


        async loadRoles() {
            this.$Progress.start();
            await this.axios
                .get("/roles")
                .then((response) => {
                    this.roles = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        deleteRole(id) {
            this.$swal({
                    title: "Möchtest du die Benutzerrolle wirklich löschen?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: `Löschen`,
                    denyButtonText: `Abbrechen`,
                })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        //Send request to the Server
                        this.form
                            .delete("/roles/" + id)
                            .then(() => {
                                this.$swal({
                                    icon: "success",
                                    title: "Benutzerrolle erfolgreich gelöscht!",
                                    toast: true,
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 2000,
                                    timerProgressBar: true,
                                    });
                                this.loadRoles();
                            })
                            .catch(() => {
                                this.$swal({
                                    icon: "error",
                                    title: "Oops...",
                                    text: "Da ist etwas schief gelaufen",
                                });
                            });
                    }
                });
        },
        editRole(id) {
            this.$Progress.start();
            this.form
                .put("/roles/"+id)
                .then(() => {
                    this.$bvModal.hide("roleModal");
                    this.$swal({
                        icon: "success",
                        title: "Benutzerrolle wurde editiert",
                    });
                    this.$Progress.finish();
                    this.loadRoles();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        createRole() {
            this.$Progress.start();
            this.form
                .post("/roles")
                .then(() => {
                    this.$bvModal.hide("roleModal");
                    this.$swal({
                        icon: "success",
                        title: "Benutzerrolle erfolgreich hinzugefügt",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.loadRoles();
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        loadGroups(guard) {
            return this.axios.get('permission-groups/guard/' + guard)
                .then((response) => {
                    this.groups = response.data;
                });
        },
    },


    computed: {
        tableData() {
            return this.roles || [];
        },

        rows() {
            return this.roles.length;
        },
    },

    created() {
        this.loadRoles();
    },


}
</script>

<style>

</style>